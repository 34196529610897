import "core-js/stable";
import "regenerator-runtime/runtime";

import { config, Config } from './config';
import { delete_old_caches } from './message'

declare var __DEBUG__: boolean;
const DEBUG = __DEBUG__;
declare var self: ServiceWorkerGlobalScope;
export {};

interface ExtendableEvent extends Event {
  waitUntil(fn: Promise<any>): void;
}


enum ResourceType {
  Content = "Content",
  Image = "Image",
  Static = "Static",
}

const cache_first_then_network_contents = [
  new RegExp("^/api/problems/problemi/categoriaevento/$"),
  new RegExp("^/api/problems/evento/$"),
  new RegExp("^/api/problems/problemi/categoria/$"),
  new RegExp("^/api/problems/partecipante/$"),
  new RegExp("^/api/problems/problemi/$"),
  new RegExp("^/api/problems/allegato/$"),
  new RegExp("^/api/problems/shortlist/$"),
  new RegExp("^/api/problems/problemi/competenza/$"),
]

const network_or_offline_cache_contents = [
  new RegExp("^/$"),
  new RegExp("^/api/whoami/$"),
  new RegExp("^/api/problems/problemi/([0-9]+)/$"),
  new RegExp("^/api/problems/problemi/comments/$"),
  new RegExp("^/api/problems/problemi/([0-9]+)/build_tex_(problema|soluzione)/$"),
]



const destroy_cache_urls = [
  new RegExp("^/accounts/logout/$"),
]

const ONLINE_MSG = JSON.stringify({
  header: "network_status",
  body: {
    online: true,
  }
})

const OFFLINE_MSG = JSON.stringify({
  header: "network_status",
  body: {
    online: false,
  }
})



interface FetchEvent extends ExtendableEvent {
  request: Request;
  respondWith(response: Promise<Response> | Response): Promise<Response>;
}


export function cacheName(key: ResourceType, opts: Config): string {
  if (key == ResourceType.Content) {
    return `${opts.version}${opts.sessionid}::${key}`;
  } else {
    return `${opts.version}${key}`;
  }
}

async function addToCache(cacheKey: string, request: Request, response: Response): Promise<Response> {
  if (response.status == 200 && response.ok) {
    const copy = response.clone();
    const cache = await caches.open(cacheKey)
    cache.put(request, copy);
  }
  return response;
}


// Todo questo è da capire meglio
async function offlineResponse(resourceType: ResourceType, opts: Config): Promise<Response> {
  if (resourceType === ResourceType.Image)
    return new Response(opts.offlineImage, { headers: { 'Content-Type': 'image/svg+xml' } });
  if (resourceType === ResourceType.Content)
    return await caches.match(opts.offlinePage) || new Response(opts.offlinePage);
  return new Response(opts.offlinePage);
}

const canBeCached = (request: Request) => {
  return request.method === "GET"
}


export const fetch_listener = (event: FetchEvent) => {
  const request = event.request;
  const acceptHeader = request.headers.get('Accept') as string;
  const url = new URL(request.url);
  let resourceType = ResourceType.Static;
  if (url.origin !== self.location.origin) {
    return;
  }
  if (!canBeCached(request)) {
    return;
  }
  if (config.blacklistCacheItems.length > 0 &&
    config.blacklistCacheItems.indexOf(url.pathname) >= 0) {
    return;
  }
  if (acceptHeader.indexOf('text/html') !== -1) {
    resourceType = ResourceType.Content;
  } else if (acceptHeader.indexOf('image') !== -1) {
    resourceType = ResourceType.Image;
  } else if (acceptHeader.indexOf('application/json') !== -1) {
    resourceType = ResourceType.Content;
  }

  const cacheKey = cacheName(resourceType, config);

  if (resourceType === ResourceType.Image) {
    event.respondWith(cacheFirst(event, request, cacheKey, resourceType))
    return;
  }

  if (resourceType == ResourceType.Static) {
    if (!DEBUG) {
      event.respondWith(cacheFirst(event, request, cacheKey, resourceType))
      return
    } else {
      event.respondWith(networkOrOfflineCache(event, request, cacheKey, resourceType))
      return
    }
  }

  if (resourceType === ResourceType.Content) {
    for (const re of cache_first_then_network_contents) {
      if (url.pathname.match(re)) {
        // @ts-ignore
        for (const [key, value] of url.searchParams) {
          if (key !== "page") {
            return;
          }
        }
        event.respondWith(cacheFirstThenNetwork(event, request, cacheKey, resourceType));
        return;
      }
    }
    for (const re of destroy_cache_urls) {
      if (url.pathname.match(re)) {
        delete_old_caches("");
        return;
      }
    }
    for (const re of network_or_offline_cache_contents) {
      if (url.pathname.match(re)) {
        event.respondWith(networkOrOfflineCache(event, request, cacheKey, resourceType))
        return;
      }
    }
    return;
  }
  return;
}


type Policy = (event: FetchEvent, request: Request, cacheKey: string, resourceType: ResourceType) => Promise<Response>;

// Cache first. Non scade mai
const cacheFirst: Policy = async (event, request, cacheKey, resourceType) => {
  const cachedResult = await caches.match(event.request);
  if (!!cachedResult) {
    return cachedResult
  }
  try {
    const response = await fetch(request);
    return await addToCache(cacheKey, request, response)
  } catch (err) {
    return offlineResponse(resourceType, config)
  }
}


const cacheFirstThenNetwork: Policy = async (event, request, cacheKey, resourceType) => {
  const cachedResponse = await caches.match(event.request);
  if (!cachedResponse) {
    try {
      const response = await fetch(request);
      return await addToCache(cacheKey, request, response)
    } catch (err) {
      throw err;
    }
  }

  event.waitUntil(fetch(request)
    .then(async (response) => {
      await addToCache(cacheKey, request, response);
      const json = await response.json();
      const clients = await self.clients.matchAll();
      clients.forEach(client => {
        client.postMessage(JSON.stringify({
          header: "cached_response",
          body: {
            origin: request.url,
            json: json,
          }
        }))
        client.postMessage(ONLINE_MSG)
      })
      return;
    })
    .catch(async (err) => {
      const clients = await self.clients.matchAll();
      clients.forEach(client => {
        client.postMessage(OFFLINE_MSG);
      })
      throw err;
    }))

  return cachedResponse.clone();
}


const networkOrOfflineCache: Policy = async (event, request, cacheKey, resourceType) => {
  try {
    const response = await fetch(request);
    event.waitUntil(addToCache(cacheKey, request, response))
    return response;
  } catch (err) {
    const cachedResponse = await caches.match(event.request);
    if (!cachedResponse) {
      throw err;
    }
    const clients = await self.clients.matchAll();
    clients.forEach(client => client.postMessage(OFFLINE_MSG))
    return cachedResponse;
  }
}
