import { cacheName } from './cache'
import { config } from './config'


declare var self: ServiceWorkerGlobalScope;
export {};


interface MessageEvent extends ExtendableEvent {
  data: string
}



export const delete_old_caches = async (sessionid: string) => {
  const cacheNames = await self.caches.keys();
  cacheNames.filter((cacheName) => {
    if (cacheName === `${config.version}Static`) {
      return false;
    }
    if (cacheName === `${config.version}Image`) {
      return false;
    }
    return cacheName !== `${config.version}${sessionid}::Content`;
  })
    .map((cacheName) => self.caches.delete(cacheName))
  config.sessionid = sessionid;
}



export const message_listener = (event: MessageEvent) => {
  let message;
  try {
    message = JSON.parse(event.data);
  } catch (e) {
    return;
  }
  switch (message.header) {
    case "sessionid":
      const { sessionid } = message.body;
      event.waitUntil(delete_old_caches(sessionid));
      break;
    default:
      console.error("Service worker received message with unknown header", message.header);
  }
}
